import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { provideIcons } from '@ng-icons/core';
import { ngIcons } from '@wheere-front/inside-shared-data-access';
import { KeycloakUserConnectService } from '@wheere-front/shared-auth';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  template: `<router-outlet />`,
  providers: [provideIcons(ngIcons)],
})
export class AppComponent {
  #keycloakUserConnectService = inject(KeycloakUserConnectService);

  constructor() {
    this.#keycloakUserConnectService.init();
  }
}
