import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { provideIcons } from '@ng-icons/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { combineLatest, filter, of, switchMap, tap } from 'rxjs';

import { ngIcons, Role, UserStore } from 'src/app/shared/data';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  template: `<router-outlet />`,
  providers: [provideIcons(ngIcons)],
})
export class AppComponent {
  #keycloak = inject(KeycloakService);
  #store = inject(UserStore);

  constructor() {
    // Note: not sure if it's required to update token with silent refresh ?
    this.#keycloak.keycloakEvents$
      .asObservable()
      .pipe(
        filter(({ type }) => type === KeycloakEventType.OnTokenExpired),
        tap(() => this.#keycloak.updateToken(20)),
        takeUntilDestroyed()
      )
      .subscribe();

    of(this.#keycloak.isLoggedIn())
      .pipe(
        filter(Boolean),
        switchMap(() =>
          combineLatest([
            this.#keycloak.loadUserProfile(),
            of(this.#keycloak.getUserRoles(true) as Role[]),
          ])
        ),
        tap(([profile, roles]) => {
          this.#store.loadUser({ profile, roles });
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }
}
