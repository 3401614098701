import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideTransloco } from '@jsverse/transloco';
import { provideHotToastConfig } from '@ngxpert/hot-toast';
import { provideFastSVG } from '@push-based/ngx-fast-svg';

import { BASE_PATH as OPENAPI_GATEWAY } from '@wheere/api-gateway';
import { BASE_PATH as OPENAPI_TRACKER } from '@wheere/api-tracker';
import { routes } from 'src/app/app.routes';
import { authInterceptor, provideKeycloak } from 'src/app/shared/data';
import { provideTooltip } from 'src/app/shared/ui';
import { configTransloco } from 'src/app/shared/util';
import { environment } from 'src/environments';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: OPENAPI_TRACKER, useValue: environment.api.tracker },
    { provide: OPENAPI_GATEWAY, useValue: environment.api.gateway },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withComponentInputBinding()),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideKeycloak(),
    provideTransloco(configTransloco),
    provideTooltip(),
    provideHotToastConfig(),
    provideFastSVG({
      url: (name: string) => `assets/svg/${name}.svg`,
    }),
  ],
};
