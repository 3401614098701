import { Routes } from '@angular/router';
import {
  authenticationGuard,
  DEVICE_ROLES,
  GATEWAY_ROLES,
  userHasAnyRole,
} from './shared/data';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    loadComponent: () => import('src/app/shared/feature/home.component'),
    canActivate: [authenticationGuard()],
  },
  {
    path: 'account',
    loadComponent: () => import('src/app/shared/feature/account.page'),
    canActivate: [authenticationGuard()],
  },
  {
    path: 'devices',
    loadChildren: () => import('src/app/domains/device/devices.routes'),
    canActivate: [authenticationGuard(), userHasAnyRole(DEVICE_ROLES)],
  },
  {
    path: 'gateways',
    loadChildren: () => import('src/app/domains/gateway/gateways.routes'),
    canActivate: [authenticationGuard(), userHasAnyRole(GATEWAY_ROLES)],
  },
  {
    path: 'forbidden',
    loadComponent: () => import('src/app/shared/feature/forbidden.page'),
  },
  {
    path: '**',
    loadComponent: () => import('src/app/shared/feature/not-found.page'),
  },
];
